import { FC } from 'react'
import styled from 'styled-components'
import {
  grid,
  width,
  WidthProps,
  GridProps,
  space,
  SpaceProps,
  justifyContent,
  alignItems,
  JustifyContentProps,
  AlignItemsProps
} from 'styled-system'

export type GridType = GridProps & SpaceProps & WidthProps & JustifyContentProps & AlignItemsProps

export const Grid: FC<GridType> = styled.div<GridType>`
  display: grid;
  ${width};
  ${grid};
  ${space};
  ${justifyContent};
  ${alignItems};
`
