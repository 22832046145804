import { notEmpty, pushFormAction, pushKeyPress } from '@jtb-don-fe/utils'
import { omit } from 'ramda'
import React, {
  ClipboardEvent, ComponentType, FocusEvent, memo, MouseEvent, RefObject, useEffect, useState
} from 'react'

import { ErrorSpecific } from './input-base-error-specific-styled'
import { Error } from './input-base-error-styled'
import { Wrapper } from './input-wrapper-styled'

export interface InputBaseProps {
  name: string
  value?: string
  disabled?: boolean
  placeholder?: string
  errors?: Array<{ code: string; message: string }>
  showError?: boolean
  dirty?: boolean
  updating?: boolean
  focused?: boolean
  type?: string
  onChange?: (value: string) => void
  onFocus?: (event: FocusEvent) => void
  onBlur?: (event: FocusEvent) => void
  onPaste?: (value: string, event: ClipboardEvent) => void
  onMouseEnter?: (event: MouseEvent) => void
  onMouseLeave?: (event: MouseEvent) => void
  position?: number
  refs?: object
  inputRef?: RefObject<HTMLInputElement>
  inputMode?: string
  autoComplete?: string
  specificErrors?: Array<{code: string; message: string}>
  warn?: boolean
}

export type WithInputType<P> = P & InputBaseProps

const omitInternalProps = (props: object) => omit(['beName'], props)

export function InputBase<P extends object>(Component: ComponentType<P>) {
  function WithInput(props: WithInputType<P>) {
    const {
      value, placeholder = '', errors = [], onChange, onFocus, onBlur, onPaste, onMouseEnter, onMouseLeave, position,
      inputRef, refs, specificErrors, ...rest
    } = props

    const [active, setActive] = useState(false)

    const getPlaceholder = () => (active ? '' : placeholder)

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e?.target?.value)
    }

    const onPasteHandler = (e: ClipboardEvent) => {
      const value = e.clipboardData.getData('text/plain')

      onPaste && onPaste(value, e)
    }

    const onFocusHandler = (event: FocusEvent) => {
      onFocus && onFocus(event)
      setActive(true)
      pushFormAction('form field entry', props.name, rest.dirty ? props.errors?.length : 0)
    }

    const onBlurHandler = (event: FocusEvent) => {
      onBlur && onBlur(event)
      setActive(false)
      pushFormAction('form field exit', props.name, props.errors?.length)
    }

    const onMouseEnterHandler = (event: MouseEvent) => {
      onMouseEnter && onMouseEnter(event)
    }

    const onMouseLeaveHandler = (event: MouseEvent) => {
      onMouseLeave && onMouseLeave(event)
    }

    const onKeyPressHandler = (event: KeyboardEvent) => {
      if (event.key === '/' && rest.name === 'personalNumber') {
        pushKeyPress('/')
      }
    }

    const showSpecificError = notEmpty(specificErrors) && !value?.match(/\d+/)
    const showError = notEmpty(errors) && (rest.updating || rest.dirty)
    const error = showError && notEmpty(errors) && !active
    const restProps = omitInternalProps(props)
    const type = props?.type

    useEffect(() => {
      if (inputRef?.current && position !== undefined && type === 'text') {
        inputRef.current.setSelectionRange(position, position)
      }
    }, [inputRef, position, value, type])

    return (
      <>
        <Wrapper>
          <Component
            {...restProps as P}
            {...refs}
            value={value || ''}
            active={active}
            errors={showSpecificError ? specificErrors : errors}
            showError={showSpecificError || showError}
            data-error={(!!error).toString()}
            placeholder={getPlaceholder()}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onPaste={onPasteHandler}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onKeyPress={onKeyPressHandler}
          />
          {(error || showSpecificError) && (
            <Error
              data-test-id={`fieldError-${props.name}`}
              inputValue={value}
            >
              {showSpecificError ? '' : errors[0].message}
            </Error>
          )}
        </Wrapper>
        {showSpecificError && <ErrorSpecific>{specificErrors?.[0].message}</ErrorSpecific>}
      </>
    )
  }

  return memo(WithInput)
}
