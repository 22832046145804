import React, { FC, memo, useCallback } from 'react'
import { NumberFormatValues, NumberFormatProps } from 'react-number-format'

import { InputBase, InputBaseProps } from '../../base/input-base'

import { InputSmsStyled } from './input-sms-styled'

export type InputSmsViewType = InputBaseProps & Partial<NumberFormatProps>

const Input = InputBase<Partial<NumberFormatProps>>(InputSmsStyled)

export const InputSmsView: FC<InputSmsViewType> = memo((props) => {
  const onValueChange = useCallback(({ value }: NumberFormatValues) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }, [props])

  return (
    <Input
      {...props}
      allowEmptyFormatting
      mask='-'
      format='########'
      onValueChange={onValueChange}
      type='tel'
      inputMode='numeric'
      autoComplete='one-time-code'
    />
  )
})
